<template>
  <div style="background: #f6f6f6;color: #000;">
    <!-- <van-nav-bar title="报价单" :fixed="true" :z-index="999">
    </van-nav-bar> -->
    <div class="nav-bar">报价单</div>
    <div class="bj"></div>
    <div class="mian">
      <div class="mian_a">
        <div class="carNo">{{form.carNo}}</div>
        <div><span style="color: #333;">车型：</span>{{form.carModel}}</div>
        <div><span style="color: #333;">车架号：</span>{{form.vin}}</div>
        <div><span style="color: #333;">进厂时间：</span>{{form.createdDate}} <span style="margin-left: 18px;color: #333;">里程：</span><span>{{form.driveMiles}}</span></div>
      </div>
      <div class="mian_b">
        <div style="font-weight: bold;">项目详情</div>
        <div class="list" v-for="(item,idx) in form.orderItems" :key="idx">
          <div class="flx xmdiv">
            <div>{{ idx+1 }}.{{item.itemName}}</div>
            <div><span class="xiaoji">小计：</span>￥{{item.xj?item.xj.toFixed(2):'0.00'}}</div>

          </div>
          <div class="flx pjdiv" v-for="(items,is) in item.orderPartList" :key="is">
            <div>{{items.partName}} <span>x{{items.counts}}</span></div>
            <div>￥{{items.price?items.price.toFixed(2):'0.00' }}</div>
          </div>
          <div class="flx pjdiv">
            <div>工时费 </div>
            <div>￥{{item.itemTotal?item.itemTotal.toFixed(2):'0.00'}}</div>
          </div>
        </div>
      </div>

      <div class="flxs" v-if="form.dis > 0">
        整单优惠
        <div>
          ￥{{form.dis?form.dis.toFixed(2):'0.00'}}
        </div>

      </div>
      <div class="flis" v-if="form.remark">
        <div style="width: 50px;">备注：</div>

        <div style="flex: 1;color: #666;">{{form.remark}}</div>
      </div>
      <div class="mian_b name">{{form.garageName}}</div>

    </div>
    <div style="height: 60px;"></div>
    <div class="bot">
      <div>合计：<span style="color: red;" class="kin">￥{{heji?heji.toFixed(2):'0.00'}}</span></div>
      <div>
        <a :href="'tel:'+form.phone">
          <van-button class="bost" type="danger" plain round size="small">
            电话咨询
          </van-button>
        </a>
        <van-button class="bost" type="danger" v-if="form.accept==0" @click="jieshou" round size="small">接受报价</van-button>
        <van-button class="bost" type="danger" v-else round size="small" disabled>已接受报价</van-button>
      </div>

    </div>
  </div>
</template>
  
<script>
import {
  getquotedetails,
  jsaccept,
  getGarageInfo,
  wxLogin,
  getphone,
  ordercar,
  postcbbpay,
  fixpay,
} from "@/api/check";

export default {
  data() {
    return {
      // , "余额充值"
      carlist: [],
      gid: this.$route.query.gid,
      id: this.$route.query.id,
      phoneisok: false,
      acvred: null,
      form: {},
      amount: "",
      amount2: "",
      remark: "",
      payType: 2,
      heji: 0,
      yfkisok: false,
      acv: 0,
      form: {},
      show: false,
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")) || {},
      user: JSON.parse(localStorage.getItem("userInfo")),
      token: "",
    };
  },

  methods: {
    //根据车牌查询未结算信息
    onLoad() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中...",
      });
      getquotedetails({ id: this.id }).then((res) => {
        loading.clear();
        if (res.code == 200) {
          var orderItems = res.data.orderItems;
          this.form = res.data;
          var heji = 0;
          for (var i in orderItems) {
            orderItems[i].xj = orderItems[i].itemTotal * orderItems[i].counts;
            for (var j in orderItems[i].orderPartList) {
              orderItems[i].xj +=
                orderItems[i].orderPartList[j].price *
                orderItems[i].orderPartList[j].counts;
            }
            heji += orderItems[i].xj;
          }
          this.heji = heji;
        }
      });
    },
    jieshou() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否确认接受报价？",
        })
        .then(() => {
          jsaccept({ id: this.id }).then((res) => {
            if (res.code == 200) {
              this.form.accept == 1;
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    back() {
      parent.WeixinJSBridge.call("closeWindow");
    },
    isWeixin() {
      //判断是否是微信
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.payType = 2;
        return true;
      } else {
        this.payType = 1;
        return false;
      }
    },
    //判断是否为支付宝坏境
    isAlipayClient() {
      if (navigator.userAgent.indexOf("AlipayClient") > -1) {
        this.payType = 1;
        return true;
      }
      this.payType = 2;
      return false;
    },
  },
  created() {
    //判断是否在微信环境
    // this.isWeixin();
    // this.onLoad();
    // return;
    this.id = this.$route.query.id;
    this.gid = this.$route.query.gid;
    var url = this.$route.path + "?gid=" + this.gid + "&id=" + this.id;
    localStorage.setItem("beforeUrl", url);
    // return
    sessionStorage.setItem("Agid", this.gid);
    sessionStorage.setItem("gid", this.gid);
    getGarageInfo({
      gid: this.gid,
    })
      .then((e) => {
        // loading.clear();
        this.shangjia = e.data;
        sessionStorage.setItem("appid", e.data.idd);
        sessionStorage.setItem("shangjia", JSON.stringify(this.shangjia));
        if (localStorage.getItem("Authorization")) {
          this.onLoad();
        } else {
          // 正式
          const redirect_uri = encodeURIComponent(
            "https://shopping.car-posthouse.cn/auths"
          );
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
          // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
          return;
        }
      })
      .catch(() => loading.clear());
  },
  mounted() {},
  computed: {},
};
</script>
  <style>
#app {
  background: #f6f6f6 !important;
}
</style>
<style lang="less" scoped>
.van-nav-bar {
  background: none;
  border: none;
}
.flxs {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding: 8px 12px;
  margin: 0 12px;
  border-radius: 4px;
}
.flis {
  background: #fff;
  display: flex;

  margin-top: 10px;
  padding: 12px 12px;
  margin: 0 12px;
  border-radius: 4px;
}
.nav-bar {
  height: 46px;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: 46px;
  z-index: 999;
  font-size: 18px;
  background: linear-gradient(rgb(227, 45, 45), rgba(227, 45, 45, 0.1));
}
.bj {
  height: 220px;
  background: linear-gradient(rgb(227, 45, 45), rgba(249, 155, 155, 0.1));
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.mian {
  padding-top: 46px;
  position: relative;
  font-size: 15px;
  .list {
    border-top: 1px dashed #dcdcdc;
  }

  .mian_a {
    margin: 10px 12px;
    background: #fff;
    // height: 120px;
    padding: 12px 12px 10px 12px;
    border-radius: 6px;
    box-shadow: 0px 6px 20px 0px rgba(227, 233, 237, 0.36);
    font-size: 12px;
    color: #666;

    .carNo {
      font-size: 18px;
      color: #000;
      font-weight: bold;
    }
  }
  .mian_b {
    margin: 10px 12px;
    background: #fff;
    padding: 12px 12px 10px 12px;
    border-radius: 6px;
    box-shadow: 0px 6px 20px 0px rgba(227, 233, 237, 0.36);
    .list:nth-child(2) {
      border: none;
    }
    .flx {
      display: flex;
      justify-content: space-between;
    }
    .xmdiv {
      background: #f6f6f6;
      padding: 10px 10px;
      border-radius: 5px;
      margin-top: 15px;
      font-size: 15px;
    }
    .pjdiv {
      padding: 10px 10px;
      font-size: 14px;
      color: #333;
    }
  }
  .name {
    padding: 18px 12px;
    font-size: 17px;
  }
}
.xiaoji {
  font-size: 12px;
  color: #666;
}
.bot {
  position: fixed;
  height: 54px;
  background: #fff;
  z-index: 9;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  font-size: 14px;
  border-top: 1px solid #ebebeb;
  .bost {
    padding: 0 16px;
    font-size: 15px;
  }
  .kin {
    font-size: 15px;
  }
  .bost:nth-child(1) {
    margin-right: 12px;
  }
}
</style>

  
  